@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(../../assets/fonts/Lato-Regular.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(../../assets/fonts/Lato-Bol.ttf) format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(../../assets/fonts/Lato-Lig.ttf) format('truetype');
  font-weight: 100;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif !important;
  overflow-x: hidden;
}

p {
  font-size: 20px;
  color: #fff;
  z-index: 10;
  line-height: 1.4;
  color: rgba(0,0,0, 0.9);
}

@media (max-width:1130px) {
  p {
    font-size: 18px;
  }
}

#pageWrap {
  width: 100%;
}