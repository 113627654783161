.transparent-modal {
  background: rgba(255,255,255,0.9) !important;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.1);
  font-family: 'Lato';
}

.transparent-overlay {
  background: rgba(255,255,255,0.3) !important;
}

@media (max-width:1130px) {
  .transparent-overlay {
    padding: 0;
  }
  .transparent-modal {
    padding: 25px !important;
  }
}